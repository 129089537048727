:root {
  --bg-primary: #f1f1f1;
  --bg-secondary: #ffffff;

  --text-primary: #121212;
  --text-secondary: #6e6e6e;

  --bg-accent: rgb(255, 64, 0);
  --bg-accent-hover: rgb(239, 62, 2);
  --bg-accent-active: rgb(233, 58, 0);
  --bg-accent-transparent: rgba(255, 64, 0, 0.2);
  --bg-accent-transparent-darker: rgba(255, 64, 0, 0.4);

  --error-color: #ff1f1f;

  --border-radius: 0.375rem;
}
