#error-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


#error-page h1 {
    font-size: 36px;
    font-family: 'Bungee Spice', cursive;
}


#error-page p {
    font-size: 24px;
    color: rgba(0,0,0,0.5);
    font-weight: 200;
    margin-block: 2rem;
}