.game-log-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 60px);
  overflow-y: auto;
  position: relative;
}


.game-log-page h1 {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3rem;
}

.commentary-container {
  position: absolute;
  top: 6rem;
  right: 4rem;
  width: 366.8px;
}


.commentary-container h2 {
    font-size: 1.25rem;
    font-weight: semibold;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 0.375rem 0.375rem 0 0;
    padding: 0.25rem;
}

.live-commentary {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  height: 370px;
  overflow-y: auto;
}
