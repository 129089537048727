.home-page {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.game-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.game-intro h1 {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.game-start {
    margin-top: 3rem;
  width: 90vw;
  background-color: var(--bg-secondary);
  padding: 2rem;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media screen and (min-width: 390px) {
  .game-start {
    flex-direction: row;
  }
}

@media screen and (min-width: 576px) {
  .game-start {
    width: 65vw;
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .game-start {
    width: 50vw;
  }
}

@media screen and (min-width: 1024px) {
  .game-start {
    width: 40vw;
  }
}

@media screen and (min-width: 1200px) {
  .game-start {
    width: 30vw;
  }
}
