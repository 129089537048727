.login-page {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.login-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: var(--bg-secondary);
    padding: 2rem;
    border-radius: var(--border-radius);
}

.login-form__error {
    color: var(--error-color);
    font-size: 0.8rem;
}


.login-form h1 {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
}