.game-history-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--bg-secondary);
}

.game-history-entry__name {
    display: flex;
    align-items: center;
    gap: 1rem;
}