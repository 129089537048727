.form-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-item--horizontal {
  align-items: center;
  gap: 1rem;
}

.form-item input,
.form-item select {
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  background-color: transparent;
  color: var(--text-primary);
  border: 1px solid var(--text-secondary);
  outline: 2px solid transparent;
  transition: all 200ms ease-in-out;

  /* set accent color */
  accent-color: var(--bg-accent);
}

.form-item input:focus,
.form-item select:focus {
  outline: 2px solid var(--bg-accent);
  border: 1px solid transparent;
}

@media screen and (min-width: 290px) {
  .form-item--horizontal {
    flex-direction: row;
  }
}
