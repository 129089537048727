.game-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.game-page h1 {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3rem;
}

.game-board {
  margin-top: 3rem;
  gap: 0.25rem;
}

.game-board__cell {
  background-color: burlywood;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}


.game-board__cell--completed {
  cursor: not-allowed;
}