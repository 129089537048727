.app-button {
  transition: all 200ms ease-in-out;
  background-color: var(--bg-accent);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.app-button:hover {
  background-color: var(--bg-accent-hover);
}

.app-button:active {
  background-color: var(--bg-accent-active);
}

.app-button.app-button--text {
  background-color: transparent;
  color: var(--bg-accent);
}

.app-button.app-button--text:hover {
  background-color: var(--bg-accent-transparent);
}

.app-button.app-button--text:active {
  background-color: var(--bg-accent-transparent-darker);
}
