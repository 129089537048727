/* Importing the fonts */
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap&text=GomokuOops!");



/* Importing the css reset to easy styling */
@import "./reset.css";
/* Importing the css variables for easy styling */
@import "./variables.css";
/* Importing styles for pages */
@import "./pages/index.css";
/* Importing styles for components */
@import "./components/index.css";

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "Readex Pro", sans-serif;
  color: var(--text-primary);
  background-color: var(--bg-primary);
}


*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bg-accent-transparent-darker);
}

*::-webkit-scrollbar-thumb:hover {
  background-color:var(--bg-accent-transparent-darker);
}
