.games-page {
  height: calc(100vh - 60px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.games-page h1 {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  margin-top: 7rem;
}

.game-history-entries {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  width: 90vw;
  margin-bottom: 3rem;
}

@media screen and (min-width: 576px) {
  .game-history-entries {
    width: 80vw;
  }
}

@media screen and (min-width: 768px) {
  .game-history-entries {
    width: 70vw;
  }
}

@media screen and (min-width: 1024px) {
  .game-history-entries {
    width: 60vw;
  }
}

@media screen and (min-width: 1200px) {
  .game-history-entries {
    width: 50vw;
  }
}
