.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1rem;
    height: 60px;
}


.app-header__brand {
    font-size: 36px;
    font-family: 'Bungee Spice', cursive;
}

.app-header__nav-list {
    display: flex;
    align-items: center;
    gap: 1rem;
}


@media screen and (min-width: 768px) {
    .app-header {
        padding-inline: 1.5rem;
    }
}


@media screen and (min-width: 1024px) {
    .app-header {
        padding-inline: 2rem;
    }
}


@media screen and (min-width: 1200px) {
    .app-header {
        padding-inline: 2.5rem;
    }
}
